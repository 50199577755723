import axios from "axios";
import { DATA_ENDPOINT } from "../util/variables";
import { Collection } from "../types";
// import aboutImageSrc1 from "url:/assets/imgs/01.jpeg?as=webp&width=2560";
// import aboutImageSrc2 from "url:/assets/imgs/02.jpeg?as=webp&width=2560";
// import aboutImageSrc3 from "url:/assets/imgs/03.jpeg?as=webp&width=2560";
// import aboutImageSrc4 from "url:/assets/imgs/04.jpeg?as=webp&width=2560";
// import aboutImageSrc5 from "url:/assets/imgs/05.jpeg?as=webp&width=2560";
// import aboutImageSrc6 from "url:/assets/imgs/06.jpeg?as=webp&width=2560";

export interface Image {
  id: string;
  order: number;
  src: string;
}

export const getHomeDisplays = async () => {
  const { data } = await axios.get<Image[]>(DATA_ENDPOINT, {
    params: { type: Collection.HOME_DISPLAY },
  });
  return data.sort(({ order: o1 }, { order: o2 }) => o1 - o2);
};
