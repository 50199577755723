import axios from "axios";
import { DATA_ENDPOINT } from "../util/variables";
import { Collection } from "../types";

export interface MenuSection {
  id: string;
  order: number;
  title: string;
  items: {
    label: string;
    price: string;
  }[];
  withBorder?: boolean;
  comments?: string;
}

export const getMenu = async () => {
  const { data } = await axios.get<MenuSection[]>(DATA_ENDPOINT, {
    params: { type: Collection.MENU },
  });
  return data.sort(({ order: o1 }, { order: o2 }) => o1 - o2);
};

export function splitMenuData(data: MenuSection[]) {
  const oddResults: MenuSection[] = [];
  const evenResults: MenuSection[] = [];
  data.forEach((section, index) => {
    if (index % 2 === 0) {
      oddResults.push(section);
    } else {
      evenResults.push(section);
    }
  });
  return [oddResults, evenResults];
}
