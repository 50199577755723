import axios from "axios";
import { DATA_ENDPOINT } from "../util/variables";
import { Collection } from "../types";
// import careerSrc1 from "url:/assets/imgs/14.png?as=webp&width=1280";
// import careerSrc2 from "url:/assets/imgs/15.jpeg?as=webp&width=1280";

export interface Career {
  id: string;
  order: number;
  src: string;
  title: string;
  description: string;
}

export const getCareers = async () => {
  const { data } = await axios.get<Career[]>(DATA_ENDPOINT, {
    params: { type: Collection.CAREERS },
  });
  return data.sort(({ order: o1 }, { order: o2 }) => o1 - o2);
};
