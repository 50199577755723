import React, { forwardRef, memo, useMemo, useState } from "react";
import type { ReactElement, Ref } from "react";
import { SubmitHandler, useForm, FieldError } from "react-hook-form";
import classNames from "classnames";
import { isEmail } from "validator";
import { AnimatePresence, motion } from "framer-motion";
import newsLetterSrc from "/assets/imgs/circle-logo.svg";
import { dataStore } from "../util/useData";
import { InsButton, MetaButton } from "./Footer";
import axios from "axios";

const NEWSLETTER_URL = `${process.env.DATA_ENDPOINT || "http://localhost:3000"}/api/newsletter`;

const variants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 1,
      delay: 0.8,
    },
  },
};

const Message = ({ type, message }: FieldError) => {
  const text = useMemo(() => {
    if (type === "validate") return "Current value is invalid";
    if (type === "required") return "Current value is required";
  }, [type]);
  return <p className="text-xs text-red-600 my-1">{message || text}</p>;
};

const textValidate = (v: string | undefined) => (v?.length ?? 0) > 0;
const emailValidate = (v: string | undefined) => (v ? isEmail(v) : false);

const DismissButton = (props: React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>) => (
  <button {...props}>
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line y1="-0.5" x2="21.8167" y2="-0.5" transform="matrix(0.679814 0.733385 -0.709063 0.705145 -0.000213623 1)" stroke="black" />
      <line y1="-0.5" x2="22.0083" y2="-0.5" transform="matrix(0.686639 -0.726998 0.702426 0.711757 0.888458 17)" stroke="black" />
    </svg>
  </button>
);

const Input = memo(forwardRef((props: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, ref: Ref<HTMLInputElement>) => <input ref={ref} {...props} className={classNames("block border-[#C4C4C4] border-[1px] rounded-[8px] w-full p-[16px] placeholder:text-[#787878]", props.className)} />));

interface FormValue {
  firstName: string | undefined;
  lastName: string | undefined;
  email: string | undefined;
}

interface NewsletterProps {}

function Newsletter({}: NewsletterProps): ReactElement | null {
  const [show, setShow] = useState(true);

  const isMobile = dataStore((s) => s.isMobile);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormValue>({ mode: "onBlur" });

  const onSubmit: SubmitHandler<FormValue> = async (params) => {
    try {
      await axios.put(NEWSLETTER_URL, null, { params });
    } catch (e) {
      console.error(e);
    }
    setShow(false);
    reset();
  };

  const mobile = (
    <motion.div variants={variants} initial="hidden" animate="visible" exit="hidden" className="fixed top-0 left-0 bottom-0 right-0 backdrop-brightness-50 z-[1000]">
      <div data-testid="newsletter-div" className="fixed top-[50%] -translate-y-[50%] left-[50%] -translate-x-[50%] px-[24px] py-[20px] font-montserrat rounded-[30px] bg-white">
        <img className="mx-auto h-[38px] my-3" src={newsLetterSrc} />
        <div className="w-full text-center text-[22px] !text-black font-[300]">Sign Up for</div>
        <div className="w-full text-center text-[22px] !text-black font-[300]">Our Newsletter</div>

        <form onSubmit={handleSubmit(onSubmit)} className="mt-[24px] mb-[30px] block">
          <Input
            {...register("firstName", {
              required: true,
              validate: textValidate,
            })}
            placeholder="First Name"
            className="!w-[320px]"
          />

          {errors.firstName && <Message {...errors.firstName} />}
          <Input
            {...register("lastName", {
              required: true,
              validate: textValidate,
            })}
            placeholder="Last Name"
            className="mt-[8px] !w-[320px]"
          />
          {errors.lastName && <Message {...errors.lastName} />}
          <Input
            {...register("email", {
              required: true,
              validate: emailValidate,
            })}
            placeholder="Email Address"
            className="mt-[24px] !w-[320px]"
          />
          {errors.email && <Message {...errors.email} />}
          <button type="submit" className="w-full h-[48px] !bg-black text-white text-[16px] rounded-[8px] mt-[16px] mb-[30px]">
            Sign Up
          </button>
        </form>

        <DismissButton onClick={() => setShow(false)} className="absolute right-[24px] top-[24px] h-[16px] w-[16px]" />
      </div>
    </motion.div>
  );

  const desktop = (
    <motion.div variants={variants} initial="hidden" animate="visible" exit="hidden" className="fixed top-0 left-0 bottom-0 right-0 backdrop-brightness-50 z-[1000]">
      <div data-testid="newsletter-div" className="fixed top-[50%] -translate-y-[50%] left-[50%] -translate-x-[50%] px-[60px] py-[57px] font-montserrat rounded-[30px] bg-white">
        <img className="mx-auto h-[50px] mb-3" src={newsLetterSrc} />
        <div className="w-full text-center text-[32px] !text-black font-[300]">Sign Up for Our Newsletter</div>

        <form onSubmit={handleSubmit(onSubmit)} className="mt-[24px] mb-[30px] block font-[20px]">
          <div className="flex w-full gap-[16px] mb-[24px]">
            <div className="flex-1">
              <Input
                {...register("firstName", {
                  required: true,
                  validate: textValidate,
                })}
                placeholder="First Name"
                className="min-w-[275px]"
              />

              {errors.firstName && <Message {...errors.firstName} />}
            </div>
            <div className="flex-1">
              <Input
                {...register("lastName", {
                  required: true,
                  validate: textValidate,
                })}
                placeholder="Last Name"
                className="min-w-[275px]"
              />
              {errors.lastName && <Message {...errors.lastName} />}
            </div>
          </div>

          <div className="flex w-full gap-[16px]">
            <div className="flex-1">
              <Input
                {...register("email", {
                  required: true,
                  validate: emailValidate,
                })}
                placeholder="Email Address"
                className=""
              />
              {errors.email && <Message {...errors.email} />}
            </div>
            <button type="submit" className="w-[135px] h-[56px] !bg-black text-white text-[16px] rounded-[8px]">
              Sign Up
            </button>
          </div>
        </form>

        <DismissButton onClick={() => setShow(false)} className="absolute right-[24px] top-[24px] h-[16px] w-[16px]" />
      </div>
    </motion.div>
  );

  return <AnimatePresence mode="wait">{show ? (isMobile ? mobile : desktop) : null}</AnimatePresence>;
}

export default memo(Newsletter);

export function NewsletterFooter() {
  const isMobile = dataStore((s) => s.isMobile);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormValue>({ mode: "onBlur" });

  const onSubmit: SubmitHandler<FormValue> = async (params) => {
    try {
      await axios.put(NEWSLETTER_URL, null, { params });
    } catch (e) {
      console.error(e);
    }
    reset();
  };

  const mobile = (
    <div className="mt-[72px] mb-[98px] font-montserrat">
      <div className="w-full text-center text-[22px] !text-black font-[300]">Sign Up for</div>
      <div className="w-full text-center text-[22px] !text-black font-[300]">Our Newsletter</div>
      <form onSubmit={handleSubmit(onSubmit)} className="mt-[16px] flex flex-col gap-[16px]">
        <div className="w-full flex gap-[8px]">
          <div className="flex-1">
            <Input
              {...register("firstName", {
                required: true,
                validate: textValidate,
              })}
              placeholder="First Name"
              className="font-[14px]"
            />

            {errors.firstName && <Message {...errors.firstName} />}
          </div>
          <div className="flex-1">
            <Input
              {...register("lastName", {
                required: true,
                validate: textValidate,
              })}
              placeholder="Last Name"
              className="font-[14px]"
            />
            {errors.lastName && <Message {...errors.lastName} />}
          </div>
        </div>
        <div className="w-full flex gap-[8px]">
          <div className="flex-1">
            <Input
              {...register("email", {
                required: true,
                validate: emailValidate,
              })}
              placeholder="Email Address"
              className="font-[14px]"
            />
            {errors.email && <Message {...errors.email} />}
          </div>
          <button type="submit" className="w-[106px] h-[58px] !bg-black text-white text-[16px] rounded-[8px]">
            Sign Up
          </button>
        </div>
      </form>
    </div>
  );

  const desktop = (
    <div className="mt-[60px] mb-[40px] w-full font-montserrat">
      <div className="w-full text-center text-[32px] !text-black font-[300]">Sign Up for Our Newsletter</div>
      <form onSubmit={handleSubmit(onSubmit)} className="mt-[24px] font-[20px] flex gap-[24px] justify-center">
        <div className="flex-1 max-w-[272px]">
          <Input
            {...register("firstName", {
              required: true,
              validate: textValidate,
            })}
            placeholder="First Name"
          />

          {errors.firstName && <Message {...errors.firstName} />}
        </div>
        <div className="flex-1 max-w-[272px]">
          <Input
            {...register("lastName", {
              required: true,
              validate: textValidate,
            })}
            placeholder="Last Name"
          />
          {errors.lastName && <Message {...errors.lastName} />}
        </div>

        <div className="flex-1 max-w-[409px]">
          <Input
            {...register("email", {
              required: true,
              validate: emailValidate,
            })}
            placeholder="Email Address"
          />
          {errors.email && <Message {...errors.email} />}
        </div>

        <button type="submit" className="w-[157px] h-[56px] !bg-black text-white text-[16px] rounded-[8px]">
          Sign Up
        </button>

        <span className="flex h-[56px] gap-[24px] ml-[32px]">
          <InsButton width={40} height={40} className="!h-[56px]" />
          <MetaButton width={40} height={40} className="!h-[56px]" />
        </span>
      </form>
    </div>
  );

  return isMobile ? mobile : desktop;
}
