import React, { memo, useEffect, useRef, useState } from "react";
import type { ReactElement } from "react";
import { dataStore } from "../util/useData";
import { AnimatePresence, motion } from "framer-motion";
import { useInterval } from "usehooks-ts";
import Button from "../common/Button";

import ArrowPrevSvg from "url:/assets/imgs/hero-arrow-prev.svg?as=webp&width=45&height=45";
import ArrowNextSvg from "url:/assets/imgs/hero-arrow-next.svg?as=webp&width=45&height=45";

function HeroSlick(): ReactElement | null {
  const displays = dataStore((s) => s.homeDisplay);
  const [activedIndex, setActivedIndex] = useState(0);
  const [direction, setDirection] = useState<"left" | "right">("right");
  const timer = useRef<any>(null);
  const [stopper, setStopper] = useState<number | null>(4000);

  if (!displays) return null;

  const next = (delay?: boolean) => {
    setDirection("right");
    setActivedIndex((current) => {
      return current === displays.length - 1 ? 0 : current + 1;
    });
    if (delay) {
      delayInterval();
    }
  };

  const prev = (delay?: boolean) => {
    setDirection("left");
    setActivedIndex((current) => {
      return current === 0 ? displays.length - 1 : current - 1;
    });
    if (delay) {
      delayInterval();
    }
  };

  const delayInterval = () => {
    setStopper(null);
    if (timer.current) clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      setStopper(4000);
    }, 4000);
  };

  useInterval(() => next(false), stopper);

  useEffect(() => {
    () => {
      if (timer.current) clearTimeout(timer.current);
    };
  }, []);

  const variants = {
    hiddenNext: { opacity: 0, x: 35, zIndex: -10 },
    hiddenPrev: { opacity: 0, x: -35, zIndex: -10 },
    visible: { opacity: 1, x: 0, zIndex: 0, transition: { type: "tween", duration: 0.55 } },
    exitNext: { opacity: 0, x: -35, zIndex: -10 },
    exitPrev: { opacity: 0, x: 35, zIndex: -10 },
  };

  return (
    <div className="fixed top-navigationMobile md:top-navigation left-0 bottom-0 right-0 bg-white">
      {displays!.map(({ src }, index) => {
        const isActive = index === activedIndex;
        return (
          <AnimatePresence mode="wait">
            {isActive && <motion.img loading="lazy" key={src.slice(0, 10)} className="absolute h-full -left-[35px] w-[calc(100vw+70px)] min-w-[calc(100vw+70px)] object-center object-cover" variants={variants} initial={direction === "right" ? "hiddenNext" : "hiddenPrev"} animate="visible" exit={direction === "right" ? "exitNext" : "exitPrev"} src={src} />}
          </AnimatePresence>
        );
      })}
      <Button onClick={() => prev(true)} className="fixed top-[calc((100lvh-theme(space.navigationMobile))/2)] md:top-[calc((100lvh-theme(space.navigation))/2)] translate-y-[80px] left-[11px] md:left-[52px]">
        <img src={ArrowPrevSvg} className="w-[45px] h-[45px] object-cover" />
      </Button>
      <Button onClick={() => next(true)} className="fixed top-[calc((100lvh-theme(space.navigationMobile))/2)] md:top-[calc((100lvh-theme(space.navigation))/2)] translate-y-[80px] right-[11px] md:right-[52px]">
        <img src={ArrowNextSvg} className="w-[45px] h-[45px] object-cover" />
      </Button>
    </div>
  );
}

export default memo(HeroSlick);
