import React, { memo } from "react";
import type { ReactElement } from "react";
import classNames from "classnames";
import Button from "../common/Button";
import { dataStore } from "../util/useData";
import scrollToTopIcon from "url:/assets/imgs/scroll-to-top.jpg?as=webp";

interface ScrollToTopProps {}

function ScrollToTop({}: ScrollToTopProps): ReactElement {
  const passingHome = dataStore((s) => s.passing.home)
  
  return (
    <div data-testid="scrolltotop-div" className={classNames("fixed right-[31px] bottom-[31px] md:right-[50px] md:bottom-[50px] transition-all duration-300", {
      'opacity-100 z-[1001]': passingHome,
      'opacity-0 z-[-1001]': !passingHome,
    })}>
      <ScrollToTopButton />
    </div>
  );
}

export default memo(ScrollToTop);

const ScrollToTopButton = memo(() => (
  <Button className="!p-0 w-[55px] h-[55px] shadow-lg" onClick={() => window.scrollTo(0, 0)}>
    <img src={scrollToTopIcon} className="object-contain" />
  </Button>
));
