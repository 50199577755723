import axios from "axios";
import { DATA_ENDPOINT } from "../util/variables";
import { Collection } from "../types";
// import featureSrc1 from "url:/assets/imgs/10.jpeg?as=webp&width=1280";
// import featureSrc2 from "url:/assets/imgs/11.jpeg?as=webp&width=1280";
// import featureSrc3 from "url:/assets/imgs/12.jpeg?as=webp&width=1280";

export interface MerchandiseProduct {
  id: string;
  order: number;
  mainImage: string;
  title: string;
  description: string;
}

export const getMerchandiseProducts = async () => {
  const { data } = await axios.get<MerchandiseProduct[]>(DATA_ENDPOINT, {
    params: { type: Collection.MERCHANDISE_PRODUCTS },
  });
  return data.sort(({ order: o1 }, { order: o2 }) => o1 - o2);
};
