import classNames from "classnames";
import React, { memo, useMemo, forwardRef, PropsWithChildren, useEffect, useRef } from "react";
import HeroSlick from "./HeroSlick2";

import aboutImageSrcOne from "url:/assets/imgs/07.jpeg?as=webp&width=1280";
import aboutImageSrcTwo from "url:/assets/imgs/08.jpeg?as=webp&width=1280";

import contactHeroSrc from "url:/assets/imgs/16.jpeg?as=webp&width=2560";
import careerHeroSrc from "url:/assets/imgs/13.jpeg?as=webp&width=2560";
import aboutHeroSrc from "url:/assets/imgs/19.png?as=webp&width=2560";

import locationImageSrcOne from "url:/assets/imgs/17.jpeg?as=webp&width=1280";
import locationImageSrcTwo from "url:/assets/imgs/18.png?as=webp&width=1280";

import { AnimatedSpringImage, AnimatedSpringDiv } from "@vincecao/animated-in-view";
import { splitMenuData } from "./useMenu";
import { type DataState, dataStore } from "../util/useData";
import ScrollToTop from "./ScrollToTop";
import Newsletter from "../App/Newsletter";

const SPRING_DEFAULT = {
  distance: 20,
  duration: 950,
} as const;

function Home() {
  const [isMobile, passing] = dataStore((s) => [s.isMobile, s.passing]);

  useEffect(() => {
    if (passing.home || isMobile) dataStore.getState().showHeader();
    else dataStore.getState().hideHeader();
  }, [passing.home, isMobile]);

  return (
    <>
      <div className="test"></div>
      <div className="fixed top-0 left-0 h-screen w-screen bg-white" />
      {!passing.location && (
        <FixedHeroWindow src={contactHeroSrc} imageClassName="brightness-75">
          <div className="absolute top-[50%] -translate-y-0.5 font-fromage font-light uppercase text-white px-5 text-[48px] md:text-[64px] text-center w-full m-auto">Contact & Locations</div>
        </FixedHeroWindow>
      )}
      {!passing.career && (
        <FixedHeroWindow src={careerHeroSrc} imageClassName="brightness-75">
          <div className="absolute top-[50%] -translate-y-0.5 font-fromage font-light uppercase text-white text-[40px] md:text-[64px] text-center w-full m-auto">Career</div>
        </FixedHeroWindow>
      )}
      {!passing.features && <FixedHeroWindow src={aboutHeroSrc} />}
      {!passing.home && <HeroSlick />}

      <div className="home">
        <About isMobile={isMobile} />
        <FeatureProducts />
        <MerchandiseProducts />
        <Menu />
        <Career isMobile={isMobile} />
        <ContactLocations isMobile={isMobile} />
      </div>

      <ScrollToTop />
      <Newsletter />
    </>
  );
}

export default memo(Home);

const FixedHeroWindow = memo(({ src, imageClassName, children }: PropsWithChildren<{ src: string; imageClassName?: string }>) => {
  return (
    <div className="fixed top-0 left-0 h-screen w-screen">
      <img className={classNames("w-full h-full object-cover", imageClassName)} src={src} />
      {children}
    </div>
  );
});

const About = memo(
  forwardRef<HTMLDivElement, { isMobile: boolean }>(({ isMobile }, _ref) => {
    return (
      <BoundaryWrapper id="about" className="about" boundaryType="home">
        <div className="about-item mb-[50px] md:mb-0 !items-end">
          <AnimatedSpringDiv type="slide-left-to-right" animateDisabled={isMobile} {...SPRING_DEFAULT}>
            <div className="about-item-heading">About Angus T</div>
            <p className="md:max-w-[470px]">Quality-driven, niche, and unapologetic. At Angus T bakery, all of our pastries are hand-crafted in our open kitchen daily, using the freshest quality ingredients sourced from both France and Vancouver. While we focus on traditional methods and take no short-cuts in our process, we are unafraid to push creativity boundaries in flavours and textures.</p>
          </AnimatedSpringDiv>
          <AnimatedSpringImage src={aboutImageSrcOne} type="slide-bottom-to-top" className="landscape" {...SPRING_DEFAULT} />
        </div>
        <div className="about-item !flex-col-reverse md:!flex-row !items-start">
          <AnimatedSpringImage src={aboutImageSrcTwo} type="slide-bottom-to-top" className="portrait" {...SPRING_DEFAULT} />
          <AnimatedSpringDiv type="slide-right-to-left" animateDisabled={isMobile} {...SPRING_DEFAULT}>
            <p className="whitespace-pre-wrap md:max-w-[410px]">
              {
                "We are passionate about what we do, and we strive to do our part in contributing back to our community. \n\nWe hope for Angus T to be more than a bakery. Our vision is to bring the community together, fostering an interactive environment that brings local businesses, writers, artists, and neighbours together in making a difference. For more details, please feel free to reach out to us."
              }
            </p>
          </AnimatedSpringDiv>
        </div>
      </BoundaryWrapper>
    );
  })
);

const FeatureProducts = memo(
  forwardRef<HTMLDivElement>((_, _ref) => {
    const products = dataStore((s) => s.featureProducts);
    return (
      <>
        <div className="flex items-center justify-center !h-[calc(100vh-theme(space.navigationMobile))] md:!h-[100vh]">
          <div className="font-natural text-white text-[64px] md:text-[128px] text-center w-full m-auto">Defined by Croissants</div>
        </div>
        <BoundaryWrapper id="features" className="features" boundaryType="features">
          <div className="heading">Featured Products</div>
          <div className="feature-items">
            {products!.map((product, index) => {
              const delay = index === 0 ? 600 : index === 1 ? 0 : 1000;
              return (
                <div className="feature-item">
                  <AnimatedSpringImage type="slide-bottom-to-top" delay={delay} src={product.mainImage} alt="feature product" {...SPRING_DEFAULT} />
                  <div className="title">{product.title}</div>
                </div>
              );
            })}
          </div>
        </BoundaryWrapper>
      </>
    );
  })
);

const MerchandiseProducts = memo(
  forwardRef<HTMLDivElement>((_, _ref) => {
    const products = dataStore((s) => s.merchandiseProducts);
    return (
      <>  
        <BoundaryWrapper id="merchandise-features" className="merchandise features" boundaryType="features">
          <div className="heading">Merchandise</div>
          <div className="feature-items">
            {products!.map((product, index) => {
              const delay = index === 0 ? 600 : index === 1 ? 0 : 1000;
              return (
                <div className="feature-item">
                  <AnimatedSpringImage type="slide-bottom-to-top" delay={delay} src={product.mainImage} alt="feature product" {...SPRING_DEFAULT} />
                  <div className="title">{product.title}</div>
                  <div className="description text-center mt-2">
                    <p>
                      {product.description}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </BoundaryWrapper>
      </>
    );
  })
);

const Menu = memo(() => {
  const menuSections = dataStore((s) => s.menu);
  const [MenuColLeft, MenuColRight] = useMemo(() => splitMenuData(menuSections!), [menuSections]);

  return (
    <div id="menu" className="menu">
      <div className="heading">Menu</div>

      <div className="layout-mobile">
        {menuSections!.map(({ title, items, withBorder, comments }) => (
          <MenuSection title={title} items={items} withBorder={withBorder} comments={comments} />
        ))}
      </div>

      <div className="layout">
        <div>
          {MenuColLeft.map(({ title, items, withBorder, comments }) => (
            <MenuSection title={title} items={items} withBorder={withBorder} comments={comments} />
          ))}
        </div>
        <div>
          {MenuColRight.map(({ title, items, withBorder, comments }) => (
            <MenuSection title={title} items={items} withBorder={withBorder} comments={comments} />
          ))}
        </div>
      </div>
    </div>
  );
});

const MenuSection = memo(({ title, items, withBorder, comments }: { title: string; items: { label: string; price: string }[]; withBorder?: boolean; comments?: string }) => (
  <div className={classNames("menu-section", { "with-border": withBorder })}>
    <div className="menu-section-heading">{title}</div>
    <div className="menu-items">
      {items.map(({ label, price }) => (
        <div className="menu-item">
          <span>{label}</span>
          <span>{price}</span>
        </div>
      ))}
      <div className="menu-comments">{comments}</div>
    </div>
  </div>
));

const Career = memo(
  forwardRef<HTMLDivElement, { isMobile: boolean }>(({ isMobile }, _ref) => {
    const careers = dataStore((s) => s.careers);
    return (
      <BoundaryWrapper id="career" className="career" boundaryType="career">
        <div className="career-items">
          {careers!.map((career) => (
            <div className="career-item">
              <AnimatedSpringImage type="slide-bottom-to-top" src={career.src} alt="career-role" {...SPRING_DEFAULT} />
              <AnimatedSpringDiv type="slide-right-to-left" animateDisabled={isMobile} {...SPRING_DEFAULT}>
                <div className="title">{career.title}</div>
                <p className="description">{career.description}</p>
                <a type="button" href="mailto:info@angust.com">
                  Apply Now
                </a>
              </AnimatedSpringDiv>
            </div>
          ))}
        </div>
      </BoundaryWrapper>
    );
  })
);

const LOCATIONS = [
  {
    src: locationImageSrcOne,
    title: "Angus T",
    title2: "Yaletown",
    description: "Hours\n\nMonday – Thursday\n8:00am – 4:30pm\n\nFriday – Sunday & Holidays\n8:00am – 5:30pm",
    addressOne: "1036 Mainland Street",
    addressTwo: "Vancouver,BC V6B 2T4",
    email: "info@angust.com",
    phone: "604-559-5989",
  },

  {
    src: locationImageSrcTwo,
    title: "Angus T",
    title2: "Park Royal",
    description: `Hours\n\nMonday – Saturday\n8:00am – 7:00pm\n\nSunday and Holidays\n8:00am – 6:00pm`,
    addressOne: "794 Main Street",
    addressTwo: "West Vancouver, BC V7T 0A4",
    email: "parkroyal@angust.com",
    phone: "604-281-0690",
  },
];

const ContactLocations = memo(
  forwardRef<HTMLDivElement, { isMobile: boolean }>(({ isMobile }, _ref) => {
    return (
      <BoundaryWrapper id="contact-locations" className="contact-locations" boundaryType="location">
        <div className="contact-locations-items">
          {LOCATIONS.map((location) => (
            <div key={location.email} className="contact-locations-item">
              <AnimatedSpringImage type="slide-bottom-to-top" src={location.src} alt="contact-locations" {...SPRING_DEFAULT} />
              <AnimatedSpringDiv type="slide-right-to-left" animateDisabled={isMobile} {...SPRING_DEFAULT}>
                <div className="title">
                  <div className="md:inline-flex">{location.title}</div>
                  <div className="md:ml-2 md:inline-flex">{location.title2}</div>
                </div>
                <p className="description">{location.description}</p>
                <p className="description">{`${location.addressOne}
${location.addressTwo}`}</p>
                <div className="description underline !-mt-[15px] md:!-mt-[35px] mb-[50px]">
                  <a href={encodeURI(`http://maps.google.com/?q=${location.addressOne},${location.addressTwo}`)}>Get Direction</a>
                </div>
                <p className="description">{`${location.email}
${location.phone}`}</p>
              </AnimatedSpringDiv>
            </div>
          ))}
        </div>
      </BoundaryWrapper>
    );
  })
);

const BoundaryWrapper = memo((props: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & { boundaryType: keyof DataState["passing"] }) => {
  const { boundaryType, ...left } = props;
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function checkPosition() {
      if (!ref.current) return;
      dataStore.getState().setPassing(boundaryType, ref.current.getBoundingClientRect().top <= -100);
    }
    window.addEventListener("scroll", checkPosition);
    return () => window.removeEventListener("scroll", checkPosition);
  }, []);

  return <div {...left} ref={ref} />;
});
