import React, { memo, useEffect, useRef } from "react";
import type { PropsWithChildren, ReactElement } from "react";
import { dataStore } from "../util/useData";

function Main({ children }: PropsWithChildren<unknown>): ReactElement {
  const ref = useRef<HTMLElement>(null);

  useEffect(() => {
    checkWidth();
    function checkWidth() {
      if (!ref.current) return;
      dataStore.getState().updateIsMobile(ref.current.getBoundingClientRect().width <= 1024);
    }
    window.addEventListener("resize", checkWidth);
    return () => window.removeEventListener("resize", checkWidth);
  }, []);
  return <main ref={ref}>{children}</main>;
}

export default memo(Main);
