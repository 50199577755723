import React, { memo } from "react";
import type { PropsWithChildren, ReactElement } from "react";
import { motion } from "framer-motion";

interface NavigationProps {
  isOpen: boolean;
}

function Navigation({
  isOpen,
  children,
}: PropsWithChildren<NavigationProps>): ReactElement {
  return (
    <motion.div
      className="navigation-panel"
      initial={{ opacity: 0, y: "-120%" }}
      animate={{ opacity: isOpen ? 1 : 0, y: isOpen ? 0 : "-120%" }}
      exit={{ opacity: 0, y: "-120%" }}
      transition={{ type: "tween", damping: 15, stiffness: 100 }}
    >
      {children}
    </motion.div>
  );
}

export default memo(Navigation);
