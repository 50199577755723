import { shallow } from "zustand/shallow";
import { createWithEqualityFn } from "zustand/traditional";
import { immer } from "zustand/middleware/immer";
import { useQuery } from "@tanstack/react-query";
import { Career, getCareers } from "../Home/useCareers";
import { FeatureProduct, getFeatureProducts } from "../Home/useFeatureProducts";
import { getHomeDisplays, Image } from "../Home/useHomeDisplays";
import { getMenu, MenuSection } from "../Home/useMenu";
import { getMerchandiseProducts, MerchandiseProduct } from "../Home/useMerchandise";

export default function useData() {
  useQuery({
    queryKey: ["repoData"],
    queryFn: async () => {
      const [careers, featureProducts, homeDisplay, menu, merchandiseProducts] = await Promise.all([
        getCareers(),
        getFeatureProducts(),
        getHomeDisplays(),
        getMenu(),
        getMerchandiseProducts()
      ]);
      dataStore.getState().initialize({
        careers,
        featureProducts,
        homeDisplay,
        menu,
        merchandiseProducts
      });
    },
  });
}

export interface DataState {
  careers: Career[] | undefined;
  featureProducts: FeatureProduct[] | undefined;
  homeDisplay: Image[] | undefined;
  menu: MenuSection[] | undefined;
  merchandiseProducts: MerchandiseProduct[] | undefined;

  shouldHeaderHide: boolean;
  isMobile: boolean;
  passing: {
    home: boolean;
    features: boolean;
    career: boolean;
    location: boolean;
  };
}

const INITIAL_DATA: DataState = {
  careers: undefined,
  featureProducts: undefined,
  homeDisplay: undefined,
  menu: undefined,
  merchandiseProducts: undefined,

  shouldHeaderHide: false,
  isMobile: false,
  passing: {
    home: false,
    features: false,
    career: false,
    location: false,
  },
};

interface DataFunc {
  initialize: (
    d: Required<Omit<DataState, "shouldHeaderHide" | "isMobile" | "passing">>
  ) => void;
  showHeader: () => void;
  hideHeader: () => void;
  updateIsMobile: (flag: boolean) => void;
  setPassing: (section: keyof DataState["passing"], flag: boolean) => void;
}

export const dataStore = createWithEqualityFn(
  immer<DataState & DataFunc>((set) => ({
    ...INITIAL_DATA,
    initialize: (d) => set(() => d),
    showHeader: () => set(() => ({ shouldHeaderHide: true })),
    hideHeader: () => set(() => ({ shouldHeaderHide: false })),
    updateIsMobile: (isMobile) => set(() => ({ isMobile })),
    setPassing: (section, flag) =>
      set(({ passing }) => {
        passing[section] = flag;
      }),
  })),
  shallow
);
