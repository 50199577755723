import React, { memo, useEffect, useState } from "react";
import type { PropsWithChildren, ReactElement } from "react";
import { Outlet } from "react-router-dom";
import useScrollToTop from "../util/useScrollToTop";
import Header from "./Header";
import Footer from "./Footer";
import Main from "./Main";

import loadingSrc from "url:/assets/imgs/croissant-icon-only.gif?as=webp&width=236&height=236";
import useData, { dataStore } from "../util/useData";

function App(): ReactElement {
  useScrollToTop();
  useData();
  return (
    <Main>
      <LoadingWrapper>
        <Header />
        <Outlet />
        <Footer />
      </LoadingWrapper>
    </Main>
  );
}

export default memo(App);

function LoadingWrapper({ children }: PropsWithChildren<any>) {
  const loading = dataStore((s) => !s.careers);

  if (loading)
    return (
      <div className="fixed top-0 bottom-0 left-0 right-0 flex items-center justify-center bg-white p-10">
        <img src={loadingSrc} />
      </div>
    );
  return children;
}
